
import { defineComponent, PropType, reactive } from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'

import CommonDateInput from '@/components/common/CommonDateInput.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { FilterField } from '@/composables/types/useFilter'
import { FILTER_FIELD_KEY } from '@/views/acquisition/views/tenderings/types'

export default defineComponent({
  name: 'TenderingsFilterBar',
  components: {
    CommonDateInput,
    CommonAutocomplete,
  },
  props: {
    fields: {
      type: Array as PropType<FilterField[]>,
      required: true,
    },
  },
  setup: (props) => {
    function getFilterByKey(key: FILTER_FIELD_KEY): FilterField {
      return props.fields.find((field) => field.key === key) as FilterField
    }

    return reactive({
      icons: {
        mdiMagnify,
      },
      constants: {
        FILTER_FIELD_KEY,
      },
      functions: {
        getFilterByKey,
      },
    })
  },
})
